<template>
  <div class="backlink">
    <BaseIcon class="icon" name="ion:arrow-back" />
    <BaseWhlLink v-if="config?.linkTo" :to="config?.linkTo" class="text">{{
      content?.text || t('common.backtolist')
    }}</BaseWhlLink>
  </div>
</template>

<script lang="ts" setup>
import type { Backlink } from './models';
const { t } = useI18n();

const { content, config = { linkTo: '/' } } = defineProps<Backlink>();
</script>

<style src="./Backlink.scss" scoped lang="scss"></style>
